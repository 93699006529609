import React, { Component } from 'react'
import icons from './icons.json'

export class Icon extends Component {
  render() {
    const { size, width, height, fill, type } = this.props
    const iconWidth = width || size
    const iconHeight = height || size

    const viewBox = `0 0 ${width || 20} ${height || 30}`
    return (
      <svg width={iconWidth} height={iconHeight} viewBox={viewBox}>
        <linearGradient
          id="gradient-horizontal"
          gradientTransform="rotate(115)"
        >
          <stop offset="0%" stopColor="var(--color-stop-1)" />
          <stop offset="50%" stopColor="var(--color-stop-2)" />
          <stop offset="100%" stopColor="var(--color-stop-3)" />
        </linearGradient>
        <linearGradient
          id="gradient-vertical"
          x2="0"
          y2="1"
          gradientTransform="rotate(115)"
        >
          <stop offset="0%" stopColor="var(--color-stop-1)" />
          <stop offset="50%" stopColor="var(--color-stop-2)" />
          <stop offset="100%" stopColor="var(--color-stop-3)" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={icons[type]}
          fill={fill}
        />
      </svg>
    )
  }
}

export default Icon
