import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { Navbar } from './Navbar'
import MobileNavigation from './MobileNavigation'
import { Footer } from './Footer'
import './all.sass'

const TemplateWrapper = ({
  color,
  footerData = null,
  navbarData = null,
  children,
}) => (
  <div style={{ width: '100vw', position: 'relative' }}>
    <Helmet title="Organize with Meg">
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
    </Helmet>
    <Navbar data={navbarData} color={color} />
    <div style={{ display: 'inline-block', minHeight: '90vh', width: '100%' }}>
      {children}
    </div>
    <Footer data={footerData} color={color} />
    <MobileNavigation data={navbarData} />
  </div>
)

export const query = graphql`
  fragment LayoutFragment on Query {
    footerData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "footer" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            logoImage {
              image {
                id
                name
                publicURL
              }
              imageAlt
              tagline
            }
            copyright
            socialLinks {
              image {
                id
                name
                publicURL
              }
              imageAlt
              label
              linkURL
            }
          }
        }
      }
    }
    navbarData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "navbar" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            logoImage {
              image {
                id
                name
                publicURL
              }
              imageAlt
            }
            phoneNumber
            menuItems {
              label
              linkType
              linkURL
              linkIcon
            }
          }
        }
      }
    }
  }
`

export default TemplateWrapper
