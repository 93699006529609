import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import { Link } from 'gatsby'
import { css } from 'emotion'
import { get } from 'lodash'
import { Color, Layout, Text } from '../constants'
import Logo from './Logo'

const styles = {
  header: {
    width: '100vw',
    zIndex: 600,
    backgroundColor: 'transparent',
    // boxShadow: '0 1px 4px rgba(0,0,0,.2)',
    position: 'absolute',
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: { height: '32px', paddingRight: Layout.ICON_GAP },
  nav: { height: '100%' },
  menu: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: 4,
    paddingRight: Layout.EDGE_PADDING,
  },
  logo: {
    paddingTop: Layout.EDGE_PADDING * 1.5,
    paddingLeft: Layout.EDGE_PADDING,
  },
  mobileRight: {},
  link: {
    width: '100%',
    paddingTop: Layout.EDGE_PADDING,
    paddingBottom: Layout.EDGE_PADDING,
    color: Color.WHITE,
    fontSize: Text.SPAN,
    fontWeight: '500',
    letterSpacing: 0.5,
    '@media (max-width: 800px)': {
      display: 'none',
    },
    ':hover': {
      color: Color.PRIMARY,
    },
  },
  contactListItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 158,
    height: 'inherit',
    marginRight: Layout.INNER_PADDING,
    overflow: 'hidden',
    flexDirection: 'column',
  },
}

const linkListItem = css`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  color: ${Color.PRIMARY};
  margin-right: 16px;
  width: 72px;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 1px solid ${Color.PRIMARY};
    color: ${Color.PRIMARY};
  }
  &:hover:after {
    width: 100%;
    color: ${Color.PRIMARY};
  }
`

export class NavbarTemplate extends Component {
  render() {
    const { menuItems } = this.props.data

    return (
      <>
        <MediaQuery query="(max-width: 800px)">
          <header className="header">
            <div className={css(styles.header)}>
              <Link to="/" className={css(styles.logo)}>
                <Logo
                  height={40}
                  width={120}
                  className={css(styles.icon)}
                  color={Color.WHITE}
                />
              </Link>
            </div>
          </header>
        </MediaQuery>
        <MediaQuery query="(min-width: 801px)">
          <header className="header">
            <div className={css(styles.header)}>
              <Link to="/" className={css(styles.logo)}>
                <Logo
                  height={40}
                  width={120}
                  className={css(styles.icon)}
                  color={Color.WHITE}
                />
              </Link>
              <nav role="navigation" className={css(styles.nav)}>
                <ul className={css(styles.menu)}>
                  {menuItems &&
                    menuItems.map(({ label, linkType, linkURL }) => {
                      if (linkType === 'internal') {
                        return (
                          <li className={linkListItem} key={linkURL}>
                            <Link
                              className={css(styles.link)}
                              to={linkURL}
                              activeStyle={{
                                color: Color.PRIMARY,
                                fontWeight: '600',
                              }}
                            >
                              {label}
                            </Link>
                          </li>
                        )
                      } else {
                        return (
                          <li className={linkListItem} key={linkURL}>
                            <a className={css(styles.link)} href={linkURL}>
                              {label}
                            </a>
                          </li>
                        )
                      }
                    })}
                </ul>
              </nav>
            </div>
          </header>
        </MediaQuery>
      </>
    )
  }
}

const Navbar = props => {
  if (!props.data) {
    return null
  }
  const data = props.data.edges[0].node.frontmatter

  return <NavbarTemplate data={data} />
}

export { Navbar }
