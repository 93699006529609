import React, { Component } from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import { Color, Layout, Text } from '../constants'
import Logo from './Logo'

export const FooterTemplate = ({ data, color }) => {
  const { socialLinks, copyright } = data
  const street = '575 NW 118th Ave'
  const city = 'Portland, OR 97229'
  const styles = {
    footer: {
      // padding: Layout.EDGE_PADDING,
      '@media (max-width: 800px)': {
        position: 'relative',
        bottom: 60,
      },
      position: 'relative',
      bottom: 0,
    },
    container: {
      width: '100vw',
      minHeight: 240,
      background: color || Color.WHITE,
      paddingBottom: 0,
      paddingTop: Layout.EDGE_PADDING,
      display: 'flex',
      justifyContent: 'space-between',
      // '@media (max-width: 800px)': {
      //   flexDirection: 'column',
      //   alignItems: 'center',
      // },
      flexDirection: 'row',
      alignItems: 'flex-end',
      textAlign: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    iconWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    socialLink: {
      paddingLeft: Layout.EDGE_PADDING,
    },
    socialLinkContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: Color.ALMOST_WHITE,
      paddingRight: Layout.EDGE_PADDING,
      paddingLeft: Layout.EDGE_PADDING,
    },
    link: {
      color: Color.DARK_GRAY,
      ':hover': {
        color: `${Color.DARK_GRAY} !important`,
        textDecoration: 'underline',
      },
    },
    icon: {
      '@media (max-width: 800px)': {
        display: 'none',
        marginBottom: 0,
      },
      marginBottom: 72,
      display: 'block',
    },
    logoGroup: {
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 1,
      height: '100%',
    },
    textGroup: {
      marginLeft: 16,
      display: 'flex',
      flexDirection: 'row',
      // media query
      // '@media (max-width: 850px)': {
      //   minHeight: 280,
      //   flexDirection: 'column',
      //   alignItems: 'baseline',
      // },
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 1,
      height: '100%',
    },
    copyright: {
      height: 80,
      ...Text.SUBHEADER,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: Color.DARK_GRAY,
    },
    column: {
      minHeight: 140,
      maxWidth: 240,
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 1,
      padding: 16,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      flexDirection: 'column',
    },
    title: {
      fontSize: Text.TITLE,
      fontWeight: '600',
      textAlign: 'center',
    },
  }

  return (
    <footer className={css(styles.footer)}>
      <div className={css(styles.container)}>
        <div className={css(styles.logoGroup)}>
          <div className={css(styles.icon)}>
            <Logo height={56} width={140} type="text" color={ color === Color.DEFAULT ? Color.WHITE : Color.PRIMARY} />
          </div>
          <div className={css(styles.socialLinkContainer)}>
            <div className={css(styles.copyright)}>
              <small>{copyright}</small>
            </div>
            <div className="social">
              {socialLinks &&
                socialLinks.map(({ image, imageAlt, linkURL }) => {
                  return (
                    <a
                      key={imageAlt}
                      className={css(styles.socialLink)}
                      href={linkURL}
                    >
                      <img src={image.publicURL} alt={imageAlt} />
                    </a>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const Footer = props => {
  if (!props.data) {
    return null
  }
  const data = props.data.edges[0].node.frontmatter

  return <FooterTemplate data={data} color={props.color} />
}

export { Footer }
