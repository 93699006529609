const mainTheme = {
  PRIMARY: '#D6AE7B',
  ACCENT_PRIMARY: '#BD9A6C',
  SECONDARY: '#FA8875',
  LIGHT_TERTIARY: '#E39476',
  DARK_TERTIARY: '#F07174',
  TERTIARY: '#AF86DB',
  // PRIMARY_CTA: '#F7D8AD', // TODO: refactor out
  // PRIMARY_CTA_HOVER: '#F7D8AD', // TODO: refactor out
  // SECONDARY_CTA: '#EACDA3', // TODO: refactor out
  DEFAULT: '#1F2123',
  BACKGROUND: '#FAFAFA',
  NAV_BACKGROUND: '#FAFAFA',
  BACKGROUND_GRADIENT: `linear-gradient(219.3deg, #656769 3.25%, #949597 165.7%)`,
  BORDER_GRAY: '#656769',
  DARK_GRAY: '#656769',
  MEDIUM_GRAY: '#949597',
  LIGHT_GRAY: '#D9DADB',
  ALMOST_WHITE: '#F3F3F3',
  WHITE: '#FFFFFF',
  ACCENT_BACKGROUND: '#FDF9F3',
  BLACK: '#1F2123',
  RED: '#E74C3C',
  BLUE: '#3498DB',
  GREEN: '#27AE60',
  YELLOW: '#F1C40F',
}

export default mainTheme
