import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import { css } from 'emotion'
import { Link } from 'gatsby'
import { get } from 'lodash'
import { Color, Text } from '../../constants'
import Icon from '../Icons'

const routes = 3

const styles = {
  container: {
    zIndex: 99,
    position: 'fixed',
    display: 'grid',
    gridTemplateColumns: `repeat(${routes}, 1fr)`,
    width: '100vw',
    minHeight: 60,
    bottom: 0,
    borderTop: '1px solid rgb(231, 232, 243)',
  },
  mobileNavItem: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4%',
    paddingBottom: 'env(safe-area-inset-bottom)',
    height: '100%',
    ...Text.LABEL,
    fontWeight: 500,
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s',
    backgroundColor: Color.ALMOST_WHITE,
  },
  default: {
    color: Color.DARK_GRAY,
    backgroundColor: Color.ALMOST_WHITE,
    fontWeight: '500',
  },
  active: {
    fill: Color.PRIMARY,
    color: Color.PRIMARY,
    fontWeight: '800',
  },
}

const activeLink = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;
  padding-bottom: env(safe-area-inset-bottom);
  height: 100%;
  background-color: ${Color.NAV_BACKGROUND};
  color: ${Color.PRIMARY};
  font-weight: 600;
  svg {
    fill: ${Color.DARK_GRAY};
  }
`
const defaultLink = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;
  padding-bottom: env(safe-area-inset-bottom);
  height: 100%;
  background-color: ${Color.NAV_BACKGROUND};
  font-weight: 600;
  color: ${Color.DARK_GRAY};

  &:hover,
  &:focus {
    font-weight: 600;
    color: ${Color.DARK_GRAY};
  }

  svg {
    fill: ${Color.DARK_GRAY};
  }
`

const MobileNavItem = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? css(activeLink) : css(defaultLink),
      }
    }}
  />
)

export default class MobileNavigation extends Component {
  render() {
    const frontmatter = get(this.props, 'data.edges[0].node.frontmatter')

    return (
      <MediaQuery query="(max-width: 800px)">
        <nav role="navigation" className={css(styles.container)}>
          {frontmatter &&
            frontmatter.menuItems &&
            frontmatter.menuItems.map(
              ({ label, linkType, linkURL, linkIcon = 'home' }) => {
                if (linkType === 'internal') {
                  return (
                    <MobileNavItem key={`${linkIcon}-${linkURL}`} to={linkURL}>
                      <span>
                        <Icon type={linkIcon} size={24} />
                        <div>{label}</div>
                      </span>
                    </MobileNavItem>
                  )
                } else {
                  return (
                    <a
                      key={`${linkIcon}-${linkURL}`}
                      href={linkURL}
                      className={css(defaultLink)}
                    >
                      <span>
                        <Icon
                          type={linkIcon}
                          size={24}
                          fill={Color.DARK_GRAY}
                        />
                        <div>{label}</div>
                      </span>
                    </a>
                  )
                }
              }
            )}
        </nav>
      </MediaQuery>
    )
  }
}
