const Text = {
  FONT_FAMILY: 'Cereal',
  MAIN_TITLE: {
    fontSize: 64,
    fontWeight: '800',
  },
  PAGE_HEADING: {
    fontSize: 40,
    fontWeight: 'bold',
  },
  TITLE: {
    fontSize: 28,
    fontWeight: 'bold',
  },
  HEADER: {
    fontSize: 18,
    fontWeight: '500',
  },
  BODY: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  SUBHEADER: {
    fontSize: 16,
    fontWeight: '800',
  },
  LABEL: {
    fontSize: 12,
    fontWeight: 'normal',
  },
  SPAN: {
    fontSize: 12,
    fontWeight: '800',
  },
  BODY_XSMALL: {
    fontSize: 10,
    fontWeight: '500',
  },
}

export default Text
